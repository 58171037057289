<template>
  <g
    fill="none"
    fill-rule="evenodd"
    stroke="#79838E"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2"
  >
    <path d="M14 7.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0zM19 19l-6-6" />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
